import { observable, action, computed, makeObservable } from 'mobx';
import apiActions from 'api/actions';

export default class TalkbackStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      reset: action,
      fetchAll: action,
      fetchByActivityInstance: action,
      refetch: action,
      fetchAllCompleted: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const talkback = new Talkback(data, this.root);
    this.data.set(talkback.id, talkback);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const talkback = new Talkback(data, this.root);
      merge.set(talkback.id, talkback);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('talkbacks').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchByActivityInstance(id) {
    const requestKey = `activityInstance:${id}`;
    if (this.requests.get(requestKey) === undefined) {
      this.requests.set(requestKey, 'pending');
      return apiActions.index('talkbacks', { activity_instance_id: id }).then((response) => {
        this.addMany(response.data);
        this.requests.set(requestKey, 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      return this.fetchByActivityInstance(key.split(':')[1]);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByActivityInstanceCompleted(id) {
    const requestKey = `activityInstance:${id}`;
    return this.requests.get(requestKey) === 'complete';
  }

  talkbacksForActivityInstance(id) {
    id = parseInt(id, 10);
    return Array.from(this.data.values()).filter(talkback => (
      talkback.activity_instance_id === id
    ));
  }

  forInstanceAndQuestion(instanceId, questionId, phase) {
    instanceId = parseInt(instanceId, 10);
    questionId = parseInt(questionId, 10);
    return Array.from(this.data.values()).filter(talkback => (
      talkback.activity_instance_id === instanceId &&
      talkback.question_id === questionId &&
      talkback.stage === phase
    ));
  }

  activeForInstanceAndQuestion(instanceId, questionId, phase) {
    return this.forInstanceAndQuestion(instanceId, questionId, phase)
      .filter(talkback => talkback.active);
  }

  /**
   * Has the given user answered the question positively?
   *
   * Returns a boolean.
   */
  userAnsweredQuestion(instanceId, questionId, userId) {
    instanceId = parseInt(instanceId, 10);
    questionId = parseInt(questionId, 10);
    userId = parseInt(userId, 10);
    return Array.from(this.data.values()).filter(talkback => (
      talkback.activity_instance_id === instanceId &&
        talkback.question_id === questionId &&
        talkback.user_id === userId &&
        talkback.position === 'like' &&
        talkback.active
    )).length > 0;
  }

  forQuestionAndUser(instanceId, questionId, userId) {
    instanceId = parseInt(instanceId, 10);
    questionId = parseInt(questionId, 10);
    userId = parseInt(userId, 10);
    return Array.from(this.data.values()).filter(talkback => (
      talkback.activity_instance_id === instanceId &&
        talkback.question_id === questionId &&
        talkback.user_id === userId &&
        talkback.position === 'like' &&
        talkback.active
    ));
  }
}

export class Talkback {
  constructor(data, root) {
    this._root = root;
    this.active = data.active;
    this.activity_instance_id = data.activity_instance_id;
    this.answer_index = data.answer_index;
    this.created_at = data.created_at;
    this.id = data.id;
    this.position = data.position;
    this.question_id = data.question_id;
    this.reasoning = data.reasoning;
    this.responses = data.responses;
    this.stage = data.stage;
    this.updated_at = data.updated_at;
    this.user_id = data.user_id;
  }

  get activityInstance() {
    return this._root.ActivityInstanceStore.find(this.activity_instance_id);
  }

  get user() {
    return this._root.UserStore.find(this.user_id);
  }
}
