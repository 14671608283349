import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import actions from '../api/actions';
import RootModelStore from './root-model-store';
import RecentMessageStore from './recent-message-store';

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

let domain;

if (process.env.NODE_ENV === 'production') {
  domain = '.unyted.co';
}

const cookies = new Cookies();

export class SessionStore {
  currentUser = null;
  organization = null;
  parent = null;
  token = null;
  refresh = null;
  competitionPrompt = false;

  logout = () => {
    this.currentUser = null;
    this.organization = null;
    this.token = null;
    this.parent = null;
    this.refresh = null;
    cookies.remove('currentUser', { path: '/', domain });
    cookies.remove('token', { path: '/', domain });
    cookies.remove('org', { path: '/', domain });
    cookies.remove('refresh', { path: '/', domain });
    Sentry.configureScope(scope => {
      scope.setUser({ email: null, id: null, org_id: null });
      scope.setTag('org_id', null);
      scope.setTag('parent_id', null);
    });
    actions.teardownAuthenticated(this);
    RecentMessageStore.reset();
    setTimeout(() => {
      asyncForEach(_.values(RootModelStore.stores), store => {
        if (store.reset) return store.reset();
      });
    }, 100);
    setTimeout(() => {
      this.organization = null;
    }, 100);
  };

  login = credentials => {
    const { org, user, token, parent, refresh } = credentials;
    this.currentUser = user;
    this.token = token;
    this.organization = org;
    this.parent = parent;
    this.refresh = refresh;
    ReactGA.set({ userId: this.currentUser.id });
    Sentry.configureScope(scope => {
      scope.setUser({
        email: this.currentUser.email,
        id: this.currentUser.id,
        org_id: _.get(this.organization, 'id'),
        parent_id: _.get(this.parent, 'id'),
      });
      scope.setTag('org_id', _.get(this.organization, 'id'));
      scope.setTag('parent_id', _.get(this.parent, 'id'));
    });
    actions.setupAuthenticated(this);
    cookies.set('currentUser', this.currentUser, {
      path: '/',
      domain,
      sameSite: 'lax',
    });
    cookies.set('token', this.token, { path: '/', domain, sameSite: 'lax' });
    cookies.set('refresh', this.refresh, {
      path: '/',
      domain,
      sameSite: 'lax',
    });
    cookies.set('org', this.organization, {
      path: '/',
      domain,
      sameSite: 'lax',
    });
    cookies.set('parent', this.parent, { path: '/', domain, sameSite: 'lax' });
  };

  register = credentials => {
    this.currentUser = { ...credentials };
  };

  switchOrg = (token, newOrg, routing) => {
    routing.history.push('/');
    this.organization = newOrg;
    this.token = token;
    cookies.remove('org', { path: '/', domain });
    cookies.remove('token', { path: '/', domain });
    cookies.set('org', this.organization, {
      path: '/',
      domain,
      sameSite: 'lax',
    });
    cookies.set('token', this.token, { path: '/', domain, sameSite: 'lax' });
    Sentry.configureScope(scope => {
      scope.setUser({
        email: this.currentUser.email,
        id: this.currentUser.id,
        org_id: this.organization.id,
      });
      scope.setTag('org_id', this.organization.id);
    });
    actions.setupAuthenticated(this);
    return Promise.all(
      _.values(RootModelStore.stores).map(store => {
        if (store.refetch) {
          return store.refetch();
        } else {
          return store.reset();
        }
      }),
    );
  };

  constructor() {
    makeObservable(this, {
      currentUser: observable,
      organization: observable,
      parent: observable,
      token: observable,
      competitionPrompt: observable,
      logout: action,
      login: action,
      register: action,
      switchOrg: action,
      loggedIn: computed,
      isAssessor: computed,
    });
  }

  get loggedIn() {
    return this.currentUser !== null;
  }

  get isAssessor() {
    return this.currentUser.is_assessor || false;
  }
}

const SessionStoreInstance = new SessionStore();

export default SessionStoreInstance;
