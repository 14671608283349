import { observable, action, computed, makeObservable } from 'mobx';
import apiActions from '../../api/actions';

export default class GameLogEntryStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      fetchByActivityInstance: action
    });

    this.root = root;
  }
  /**
   * This is where retrieved data will be stored.
   */
  data = new Map();
  /**
   * This is where we will store request data, so we know which requests
   * we've performed.
   */
  requests = new Map();

  /**
   * The basic add action. Take incoming data, make a `GameLogEntry` and add
   * it to the store.
   */
  add = (logData) => {
    const gameLogEntry = new GameLogEntry(logData, this.root);
    this.data.set(gameLogEntry.id, gameLogEntry);
  }

  /**
   * Add many data items to the store. Should be used like `add` but for
   * performance reasons when we have an array of data to add.
   */
  addMany = (logDatas) => {
    const merge = new Map();
    logDatas.forEach(logData => {
      const gameLogEntry = new GameLogEntry(logData, this.root);
      merge.set(gameLogEntry.id, gameLogEntry);
    });
    this.data.merge(merge);
  }

  /**
   * Remove a data item by it's ID.
   */
  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  /**
   * Find a GameLogEntry by it's id.
   */
  find(id) {
    return this.data.get(id);
  }

  /**
   * Reset the store by clearing both `data` and `request`.
   *
   * This is useful when logging out to prevent contamination across accounts.
   */
  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  /**
   * Send an API request to fetch all GameLogEntry records the user has access
   * to.
   *
   * Returns a promise so it can be chained.
   */
  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('game_log_entries').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  /**
   * Refetch all previously completed or pending requests.
   *
   * This is useful when we know there has been a drop in the websocket
   * connection and we could have missed messages. This will put the store in
   * the most up-to-date state.
   *
   * Returns a promise so it can be chained.
   */
  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      return this.fetchByActivityInstance(key.split(':')[1]);
    }));
  }

  /**
   * Check to see if we've already fetched all available GameLogEntry records.
   */
  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  /**
   * Fetch all GameLogEntry records for an ActivityInstance using it's ID.
   *
   * Returns a promise so it can be chained.
   */
  fetchByActivityInstance(activityInstanceId) {
    const requestKey = `activityInstance:${activityInstanceId}`;
    if (this.requests.get(requestKey) === undefined) {
      this.requests.set(requestKey, 'pending');
      return apiActions.index('game_log_entries', { activity_instance_id: activityInstanceId }).then((response) => {
        this.addMany(response.data);
        this.requests.set(requestKey, 'complete');
      });
    }
    return Promise.resolve();
  }

  /**
   * Check to see if we've completed the request to fetch GameLogEntry records by the given activity instance.
   *
   * Returns true if the request is complete, but false if it's pending or
   * never been requested (undefined).
   */
  fetchByActivityInstanceCompleted(activityInstanceId) {
    const requestKey = `activityInstance:${activityInstanceId}`;
    return this.requests.get(requestKey) === 'complete';
  }

  /**
   * Get all GameLogEntry records for an ActivityInstance using it's ID.
   */
  entriesForActivityInstance(activityInstanceId) {
    const id = parseInt(activityInstanceId, 10);
    return Array.from(this.data.values()).filter((gameLogEntry) => {
      return gameLogEntry.activity_instance_id === id;
    });
  }
}

/**
 * The base class for a GameLogEntry.
 *
 * This explicitly pulls fields from the given data. In this way, we can easily see what data a GameLogEntry is comprised of.
 */
export class GameLogEntry {
  constructor(data, root) {
    this._root = root;
    this.activity_instance_id = data.activity_instance_id;
    this.answer_index = data.answer_index;
    this.category = data.category;
    this.correct = data.correct;
    this.created_at = data.created_at;
    this.id = data.id;
    this.message = data.message;
    this.points = data.points;
    this.step_id = data.step_id;
    this.updated_at = data.updated_at;
    this.user_id = data.user_id;
  }

  get user() {
    return this.user_id ? this._root.UserStore.find(this.user_id) : undefined;
  }
}
