import { observable, action, computed, makeObservable } from 'mobx';
import apiActions from 'api/actions';

export default class TrackActivityStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      fetchByTrack: action
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const trackActivity = new TrackActivity(data, this.root);
    this.data.set(trackActivity.id, trackActivity);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const trackActivity = new TrackActivity(data, this.root);
      merge.set(trackActivity.id, trackActivity);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('track_activities').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchByTrack(id) {
    const requestKey = `trackActivity:${id}`;
    if (this.requests.get('all') === undefined && this.requests.get(requestKey) === undefined) {
      this.requests.set(requestKey, 'pending');
      return apiActions.index('track_activities', { track_id: id }).then((response) => {
        this.addMany(response.data);
        this.requests.set(requestKey, 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      return this.fetchByTrack(key.split(':')[1]);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }
}

export class TrackActivity {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.order_index = data.order_index;
    this.track_id = data.track_id;
    this.activity_id = data.activity_id;
    this.inserted_at = data.inserted_at;
    this.updated_at = data.updated_at;
  }

  get activity() {
    return this._root.ActivityStore.find(this.activity_id);
  }

  get track() {
    return this._root.TrackStore.find(this.track_id);
  }
}
