import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';
import { formatGradeLevel } from 'utils/strings';

export class Domain {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.name = data.name;
    this.grade_level = data.grade_level;
    this.sort_order = data.sort_order;
    this.standard_ids = data.standard_ids;
  }

  get standards() {
    return _.map(this.standard_ids, standardId => this._root.StandardStore.find(standardId));
  }

  get subjects() {
    return this._root.SubjectStore.forDomainId(this.id);
  }

  get subject() {
    return this.subjects[0];
  }

  get gradeLevel() {
    return formatGradeLevel(this.grade_level);
  }
}

export default class DomainStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      gradeLevels: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const domain = new Domain(data, this.root);
    this.data.set(domain.id, domain);
  }

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const domain = new Domain(data, this.root);
      merge.set(domain.id, domain);
    });
    this.data.merge(merge);
  }

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.rawGet('academics/domains').then((response) => {
        this.addMany(response.data.domains);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  get gradeLevels() {
    return _.chain(this.all)
      .map('gradeLevel')
      .uniq()
      .sort()
      .value();
  }

  get uniqueDomainNames() {
    return _.chain(this.all)
      .map('name')
      .uniq()
      .sort()
      .value();
  }

  get domains() {
    return this.all;
  }

  findByNameAndGrade(name, gradeLevel) {
    return _.find(this.all, { name, grade_level: gradeLevel });
  }

  byGradeLevel(gradeLevel) {
    return _.filter(this.all, ['gradeLevel', gradeLevel]);
  }
}
