import { observable, action, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from '../../api/actions';

export default class AssessmentStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      reset: action,
      fetchByActivityId: action,
      refetch: action,
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const assessment = new Assessment(data, this.root);
    this.data.set(assessment.id, assessment);
  };

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const assessment = new Assessment(data, this.root);
      merge.set(assessment.id, assessment);
    });
    this.data.merge(merge);
  };

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  };

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  };

  fetchByActivityId(id) {
    if (this.requests.get(id) === undefined) {
      this.requests.set(id, 'pending');
      return apiActions
        .rawGet(`activities/${id}/assessment`)
        .then(response => {
          this.add(response.data);
          this.requests.set(id, 'complete');
          return this.find(id);
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.error(error);
            this.requests.set(id, 'complete');
          } else {
            throw error;
          }
        });
    }
    return Promise.resolve(this.find(id));
  }

  refetch() {
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(
      keys.map(key => {
        return this.fetchById(key);
      }),
    );
  }

  fetchByActivityIdCompleted(id) {
    return this.requests.get(id) === 'complete';
  }
}

export class Assessment {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.inserted_at = data.inserted_at;
    this.updated_at = data.updated_at;
    this.assessment_questions = data.assessment_questions;
  }

  forStagingStep(stagingStepId) {
    return _.find(this.assessment_questions, [
      'staging_step_id',
      stagingStepId,
    ]);
  }
}
