/**
 * Store the most recent WebSocket update message's ID.
 *
 * This allows us to check if we've missed a message and perform appropriate
 * actions.
 */
class RecentMessageStore {
  constructor() {
    this.lastId = null;
    this.catchingUp = false;

    this.reset = this.reset.bind(this);
  }

  reset() {
    this.lastId = null;
    this.catchingUp = false;
  }
}

const RecentMessageStoreInstance = new RecentMessageStore();

export default RecentMessageStoreInstance;
