import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from '../../api/actions';

// Wondering if we want to add this as a property or shared field to use elsewhere

const onboardingItems = {
  user: [
    'personal_overview_explanation',
    'getting_help_faq',
    'getting_help_intercom',
    'messages_explanation',
    'workroom_explanation',
    'collaborative_answering_video',
    'evaluation_explanation',
    'activity_review_explanation',
    'feedback_and_recommendations',
    'created_workroom'
  ],
  "user_creator": [
    'personal_overview_explanation',
    'getting_help_faq',
    'getting_help_intercom',
    'messages_explanation',
    'workroom_explanation',
    'collaborative_answering_video',
    'evaluation_explanation',
    'activity_review_explanation',
    'feedback_and_recommendations',
    'created_workroom'
  ],
  "team_leader": [
    'created_team',
    'added_users_roster',
    'added_users_community',
    'created_groups',
    'assigned_single_team',
    'created_single_team_assignment',
    'assigned_competition',
    'created_competition_assignment',
    'teacher_data_review_explanation',
    'viewed_skills_report',
    'getting_help_faq',
    'created_activity',
    'collaborative_answering_video'
  ],
  admin: [
    'community_overview_explanation',
    'getting_help_intercom',
    'getting_help_faq',
    'managing_users_explanation',
    'viewed_skills_report',
    'time_period_report',
    'created_activity',
    'created_groups'
  ]
};

export default class OnboardingStore {
  data = new Map();
  requests = new Map();

  add = (data) => {
    const onboarding = new Onboarding(data);
    this.data.set(onboarding.id, onboarding);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const onboarding = new Onboarding(data);
      merge.set(onboarding.id, onboarding);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  constructor() {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed
    });
  }

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('onboarding').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  forRole(role) {
    return _.find(this.all, ['role', role]);
  }
}

export class Onboarding {
  constructor(data) {
    this.id = data.id;
    this.role = data.role;
    this.user_id = data.user_id;
    this.data = data.data;
    this.skipped = data.skipped;
  }

  // NOTE: Why are we passing a role when we should already have that as part
  // of the record?
  completed(role) {
    if (!this.data || !role) return false;
    const dataKeys = Object.keys(this.data);
    return _.every(Object.keys(dataKeys), key => key) && dataKeys.length === onboardingItems[role].length;
  }
}
