import React, { Component, lazy, Suspense } from 'react';
import Intercom from 'react-intercom';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withCookies } from 'react-cookie';

// Build an icon library so we only give the user the ones we use.
import registerFaIcons from './registerFaIcons';
import { intercomId } from 'config/apiUrl';
import ErrorBoundary from 'components/shared/error-boundary';
import actions from 'api/actions';
import LoadingSpinner from 'components/shared/loading-spinner';
import { tokenExpired } from 'utils/token';

const Main = lazy(() => import('components/containers/main'));
const Unauthenticated = lazy(
  () => import('components/containers/unauthenticated'),
);

registerFaIcons();

class App extends Component {
  componentWillMount() {
    const { cookies } = this.props;
    this.getFreshAuthTokens().then(({ token, refresh }) => {
      const user = cookies.get('currentUser');
      const org = cookies.get('org');
      const parent = cookies.get('parent');
      const isLoggedIn = this.props.sessionStore.loggedIn;
      if (user && !isLoggedIn) {
        this.props.sessionStore.login({
          org,
          token,
          user,
          parent,
          refresh,
        });
      }
    });
  }

  componentDidMount() {
    const { subscribe } = this.props.routing.history;

    this.unsubscribeFromStore = subscribe(() => {
      window.scrollTo(0, 0);
      actions.track(this.props.routing.location.pathname);
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromStore();
  }

  getFreshAuthTokens() {
    const { cookies } = this.props;
    const token = cookies.get('token');
    const refresh = cookies.get('refresh');
    if (token && tokenExpired(token) && refresh) {
      return actions.refreshAuthToken(refresh);
    }
    return Promise.resolve({ token, refresh });
  }

  render() {
    const isLoggedIn = this.props.sessionStore.loggedIn;
    let user;
    if (isLoggedIn) {
      const userId = this.props.sessionStore.currentUser.id;
      const userFromStore = this.props.UserStore.find(
        this.props.sessionStore.currentUser.id,
      );
      user = {
        user_id: userId,
        email: userFromStore ? userFromStore.email : '',
        name: userFromStore ? userFromStore.name : '',
      };
    }
    const RenderElement = isLoggedIn ? Main : Unauthenticated;

    return (
      <div>
        <ErrorBoundary>
          <Intercom
            appID={intercomId}
            {...user}
            location={this.props.routing.location.pathname}
          />
        </ErrorBoundary>
        <Suspense fallback={<LoadingSpinner />}>
          <RenderElement />
        </Suspense>
      </div>
    );
  }
}

const ConnectedApp = withCookies(
  withRouter(inject('UserStore', 'routing', 'sessionStore')(observer(App))),
);

export default ConnectedApp;
