import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';
import defaultProfileImage from 'assets/img/shared/missing.png';

export default class ParentStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      fetchById: action,
      refetch: action,
      fetchAllCompleted: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const parent = new Parent(data, this.root);
    this.data.set(parent.id, parent);
    this.root.ParentCompletionStore.addMany(data.completions)
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const parent = new Parent(data, this.root);
      merge.set(parent.id, parent);
      this.root.ParentCompletionStore.addMany(data.completions);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  findParentForUser(userId) {
    return Array.from(this.data).find(parent => parent.user_id === userId);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('parent').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchById(id) {
    if (this.requests.get(id) === undefined) {
      this.requests.set(id, 'pending');
      return apiActions.get('parents', id).then((response) => {
        this.add(response.data);
        this.requests.set(id, 'complete');
        return this.find(id);
      });
    }
    return Promise.resolve(this.find(id));
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByIdCompleted(id) {
    return this.requests.get(id) === 'complete';
  }

  parentForPurchase(purchase) {
    if (purchase.parent_id) {
      return this.find(purchase.parent_id);
    }

    return _.find(this.all, { parent_email: purchase.parent_email });
  }
}

export class Parent {
  constructor(data, root) {
    this._root = root;
    this.email = data.email;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.user_id = data.user_id;
    this.id = data.id;
    this.children_ids = data.children;
    this.learning_center_ids = data.learning_center_ids;
  }

  get user() {
    return this._root.UserStore.find(this.user_id);
  }

  get children() {
    return _.chain(this.children_ids)
      .map(id => this._root.UserStore.find(id))
      .compact()
      .value();
  }

  get potentialChildren() {
    return this._root.PotentialChildStore.forParent(this.id);
  }

  get pendingPotentialChildren() {
    return this._root.PotentialChildStore.pendingForParent(this.id);
  }

  get childrenWithoutUsers() {
    return this._root.PotentialChildStore.forParentWithoutUsers(this.id);
  }

  get imagePath() {
    const user = this._root.UserStore.find(this.user_id);

    return user ?
      user.imagePath :
      defaultProfileImage;
  }

  get purchases() {
    return this._root.PlanPurchaseStore.purchasesForParent(this);
  }

  get learningCenters() {
    return this.learning_center_ids.map(centerId => this._root.LearningCenters.find(centerId));
  }
}
