import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from '../../api/actions';

export default class CompetitionStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      reset: action,
      fetchAll: action,
      fetchById: action,
      refetch: action,
      fetchAllCompleted: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const competition = new Competition(data, this.root);
    this.data.set(competition.id, competition);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const competition = new Competition(data, this.root);
      merge.set(competition.id, competition);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('competitions').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchById(id) {
    if (this.requests.get(id) === undefined) {
      this.requests.set(id, 'pending');
      return apiActions.get('competitions', id).then((response) => {
        this.add(response.data);
        this.requests.set(id, 'complete');
        return this.find(id);
      });
    }
    return Promise.resolve(this.find(id));
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      return this.fetchById(key);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByIdCompleted(id) {
    return this.requests.get(id) === 'complete';
  }

  completedCompetitionsFor(userId) {
    return Array.from(this.data.values()).filter(competition => (
      competition.competitionTeams.some(team => team.user_ids.includes(userId)) &&
        competition.completed
    ));
  }

  createdBy(userId) {
    return Array.from(this.data.values()).filter(competition => (
      competition.created_by_id === userId
    ));
  }

  incompleteCompetitionsCreatedBy(userId) {
    return this.createdBy(userId).filter(competition => (
      !competition.completed && competition.activityInstances.length > 0
    ));
  }

  completedCompetitionsCreatedBy(userId) {
    return this.createdBy(userId).filter(competition => (
      competition.completed && competition.activityInstances.length > 0
    ));
  }
}

export class Competition {
  constructor(data, root) {
    this._root = root;
    this.activity_ids = data.activity_ids;
    this.created_at = data.created_at;
    this.created_by_id = data.created_by_id;
    this.completed = data.completed;
    this.id = data.id;
    this.manually_completed_at = data.manually_completed_at;
    this.name = data.name;
    this.organization_id = data.organization_id;
    this.updated_at = data.updated_at;
  }

  get creator() {
    return this._root.UserStore.find(this.created_by_id);
  }

  get competitionTeams() {
    return this._root.CompetitionTeamStore.teamsForCompetition(this.id);
  }

  get activityInstances() {
    return _.flatMap(this.competitionTeams, team => team.activityInstances);
  }

  get completedActivityInstances() {
    return this.activityInstances.filter(instance => instance.completionDate);
  }

  instancesForActivity(activityId) {
    return _.filter(this.activityInstances, ['activity_id', activityId]);
  }

  teamForUser(userId) {
    return _.find(this.competitionTeams, team => {
      return team.user_ids.includes(userId);
    });
  }

  get completionDate() {
    if (this.manually_completed_at) {
      return new Date(this.manually_completed_at);
    }
    const completions = _(this.competitionTeams)
      .flatMap(team => team.activityInstances)
      .map(instance => instance.completionDate)
      .value();
    if (_.every(completions)) {
      return _.chain(completions)
        .compact()
        .sortBy(completion => new Date(completion))
        .head()
        .value();
    }
    return null;
  }

  get activities() {
    return _.compact(this.activity_ids.map(id => this._root.ActivityStore.find(id)));
  }

  get totalTime() {
    return this.activities.reduce((acc, activity) => acc + activity.totalTime, 0);
  }

  get teamPoints() {
    return this.competitionTeams.reduce((acc, team) => acc + team.totalPoints, 0);
  }

  get totalPoints() {
    return this.activities.reduce((acc, activity) => acc + activity.totalPoints, 0);
  }
}
