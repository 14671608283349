import { observable, action, makeObservable } from 'mobx';

class ConfettiStore {
  active = false;

  constructor() {
    makeObservable(this, {
      active: observable,
      throw: action.bound,
      off: action.bound,
    });
  }

  throw() {
    this.active = true;
  }

  off() {
    this.active = false;
  }
}

const ConfettiStoreInstance = new ConfettiStore();

export default ConfettiStoreInstance;
