import { observable, action, makeObservable } from 'mobx';

class FilterStore {
  searches = {};

  constructor() {
    makeObservable(this, {
      searches: observable,
      set: action,
    });
  }

  set(name, value) {
    this.searches = { ...this.searches, [name]: value };
  }
}

const FilterStoreInstance = new FilterStore();

export default FilterStoreInstance;
