import { observable, action, makeObservable } from 'mobx';

class NoticeStore {
  notices = [];

  constructor() {
    makeObservable(this, {
      notices: observable,
      generate: action,
      dismiss: action,
    });
  }

  generate({ message, heading }, callback = () => {}) {
    const newNotice = { message, heading, callback };
    this.notices.unshift(newNotice);
  }

  dismiss() {
    this.notices.shift().callback();
  }
}

const NoticeStoreInstance = new NoticeStore();

export default NoticeStoreInstance;
