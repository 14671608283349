import { observable, action, makeObservable } from 'mobx';
import apiActions from 'api/actions';

export default class TeamActivitiesCompletedStore {
  data = new Map();
  requests = new Map();

  add = (teamId, count) => {
    this.data.set(teamId, count);
  };

  constructor() {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      reset: action,
      fetchByTeamId: action,
      refetch: action
    });
  }

  find(teamId) {
    return this.data.get(teamId);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  };

  fetchByTeamId(teamId) {
    if (
      this.requests.get(teamId) === undefined ||
      this.requests.get(teamId) === 'completed'
    ) {
      this.requests.set(teamId, 'pending');
      return apiActions
        .rawGet(`teams/${teamId}/completed_activities`)
        .then(response => {
          this.add(teamId, response.data.completed_activities);
          this.requests.set(teamId, 'complete');
          return this.find(teamId);
        });
    }
    return Promise.resolve(this.find(teamId));
  }

  refetch() {
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(
      keys.map(key => {
        return this.fetchByTeamId(key);
      }),
    );
  }
}
