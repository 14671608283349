import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import defaultProfileImage from 'assets/img/shared/missing.png';
import imagePath from 'utils/imagePath';
import apiActions from 'api/actions';

export default class PotentialChildStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      fetchAllByParent: action,
      refetch: action,
      fetchAllCompleted: computed,
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const potentialChild = new PotentialChild(data, this.root);
    this.data.set(potentialChild.id, potentialChild);
  };

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const potentialChild = new PotentialChild(data, this.root);
      merge.set(potentialChild.id, potentialChild);
    });
    this.data.merge(merge);
  };

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  };

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  };

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.rawGet('potential_children').then(response => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchAllByParent(parentId) {
    if (this.requests.get(`parent:${parentId}`) === undefined) {
      this.requests.set(`parent:${parentId}`, 'pending');
      return apiActions
        .rawGet(`parents/${parentId}/potential_children`)
        .then(({ data }) => {
          this.addMany(data);
          this.requests.set(`parent:${parentId}`, 'complete');
        });
    }
    return Promise.resolve();
  }

  fetchAllByParentCompleted(parentId) {
    return this.requests.get(`parent:${parentId}`) === 'complete';
  }

  forParent(parentId) {
    return _.filter(this.all, ['parent_id', parentId]);
  }

  pendingForParent(parentId) {
    return _.filter(this.forParent(parentId), potentialChild =>
      _.some(potentialChild.learningCenterConnections, ['status', 'requested']),
    );
  }

  forParentWithoutUsers(parentId) {
    return _.reject(this.forParent(parentId), 'user_id');
  }

  forUser(userId) {
    return _.find(this.all, ['user_id', userId]);
  }
}

export class PotentialChild {
  constructor(data, root) {
    makeObservable(this, {
      parent: computed,
      user: computed,
      name: computed,
      selectedImages: computed,
      imagePath: computed,
      reinforcementImagePath: computed,
      selectedAreasOfFocus: computed,
      selectedGrade: computed,
      learningCenterConnections: computed,
    });

    this._root = root;
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.email = data.email;
    this.status = data.status;
    this.parent_id = data.parent_id;
    this.learning_center_id = data.learning_center_id;
    this.user_id = data.user_id;
    this.child_image_option_id = data.child_image_option_id;
    this.grade_id = data.grade_id;
    this.areas_of_focus = data.areas_of_focus;
  }

  get parent() {
    return this._root.ParentStore.find(this.parent_id);
  }

  get user() {
    return this._root.UserStore.find(this.user_id);
  }

  get name() {
    return `${this.first_name} ${this.last_name}`;
  }

  get selectedImages() {
    return this._root.ChildImageOptionStore.find(this.child_image_option_id);
  }

  get imagePath() {
    return this.child_image_option_id && this.selectedImages
      ? this.selectedImages.base_image_file_name
      : this.image_file_name
        ? imagePath(this, 'potentialchild')
        : defaultProfileImage;
  }

  get reinforcementImagePath() {
    return this.child_image_option_id && this.selectedImages
      ? this.selectedImages.reinforcement_image_file_name
      : this.image_file_name
        ? imagePath(this, 'potentialchild')
        : defaultProfileImage;
  }

  get selectedAreasOfFocus() {
    return this.areas_of_focus
      ? this.areas_of_focus.map(id => this._root.AreaOfFocusStore.find(id))
      : [];
  }

  get selectedGrade() {
    return this._root.GradeStore.find(this.grade_id);
  }

  get learningCenterConnections() {
    return this._root.PotentialChildLearningCenterStore.forPotentialChild(this);
  }

  connectionFor(learningCenter) {
    return this.learningCenterConnections.find(
      connection => connection.learning_center_id === learningCenter.id,
    );
  }
}
