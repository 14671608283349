import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

const positionKey = ['applied', 'member', 'leader', 'denied'];

export default class TeamPositionStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      length: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const teamPosition = new TeamPosition(data, this.root);
    this.data.set(teamPosition.id, teamPosition);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const teamPosition = new TeamPosition(data, this.root);
      merge.set(teamPosition.id, teamPosition);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('teamPositions').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  get length() {
    return this.data.size;
  }

  forUser(userId) {
    userId = parseInt(userId, 10);
    return Array.from(this.data.values()).filter(position => (
      position.user_id === userId
    ));
  }

  forTeam(teamId) {
    teamId = parseInt(teamId, 10);
    return Array.from(this.data.values()).filter(position => (
      position.team_id === teamId &&
        position.position !== 'applied' &&
        position.position !== 'denied'
    ));
  }

  leadersFor(teamId) {
    return this.forTeam(teamId).filter(position => position.position === 'leader');
  }

  // Check to see if the given user is a team leader of some team.
  isTeamLeader(userId) {
    return this.forUser(userId).filter(position => position.position === 'leader').length > 0;
  }

  forUserAndTeam(userId, teamId) {
    return _.find(this.all, { user_id: userId, team_id: teamId });
  }
}

export class TeamPosition {
  constructor(data, root) {
    this._root = root;
    this.created_at = data.created_at;
    this.goal = data.goal;
    this.id = data.id;
    this.pending_changes = data.pending_changes;
    this.positionInt = data.position;
    this.position = positionKey[data.position];
    this.role = data.role;
    this.team_id = data.team_id;
    this.updated_at = data.updated_at;
    this.user_id = data.user_id;
  }

  get team() {
    return this._root.TeamStore.find(this.team_id);
  }

  get user() {
    return this._root.UserStore.find(this.user_id);
  }

  isTeamLeader(organizationId) {
    return this.team &&
      this.team.organization_id === organizationId &&
      this.position === 'leader';
  }
}
