import { observable, action, computed, makeObservable } from 'mobx';
import apiActions from '../../api/actions';

export default class ActivityCategoryStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      length: computed,
      active: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const activityCategory = new ActivityCategory(data, this.root);
    this.data.set(activityCategory.id, activityCategory);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const activityCategory = new ActivityCategory(data, this.root);
      merge.set(activityCategory.id, activityCategory);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  forOrganization(org_id) {
    return Array.from(this.data.values()).filter((category) => {
      return (category.organization_id === org_id ||
        this.root.ActivityStore.all.some((activity) => activity.activity_category_id === category.id)) &&
          !category.disabled;
    });
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('activity_category').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  get length() {
    return this.data.size;
  }

  get active() {
    return this.all.filter(category => !category.disabled);
  }

  subcategoriesFor(categoryId) {
    return Array.from(this.data.values()).filter(category => category.activity_category_id === categoryId);
  }
}

export class ActivityCategory {
  constructor(data, root) {
    this._root = root;
    this.activity_category_id = data.activity_category_id;
    this.created_at = data.created_at;
    this.disabled = data.disabled;
    this.id = data.id;
    this.image = data.image;
    this.image_content_type = data.image_content_type;
    this.image_file_name = data.image_file_name;
    this.image_file_size = data.image_file_size;
    this.image_updated_at = data.image_updated_at;
    this.name = data.name;
    this.organization_id = data.organization_id;
    this.updated_at = data.updated_at;
  }

  get organization() {
    return this._root.OrganizationStore.find(this.organization_id);
  }

  get parent() {
    return this._root.ActivityCategoryStore.find(this.activity_category_id);
  }
}
