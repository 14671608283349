import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export default class AcademicSubscriptionStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const subscription = new AcademicSubscription(data, this.root);
    this.data.set(subscription.id, subscription);
  };

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const subscription = new AcademicSubscription(data, this.root);
      merge.set(subscription.id, subscription);
    });
    this.data.merge(merge);
  };

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  };

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  };

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.rawGet('/academic/subscriptions').then(response => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }
}

export class AcademicSubscription {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.stripe_id = data.stripe_id;
    this.stripe_status = data.stripe_status;
    this.stripe_current_period_end = data.stripe_current_period_end;
    this.parent_id = data.parent_id;
    this.inserted_at = data.inserted_at;
    this.updated_at = data.updated_at;
  }
}
