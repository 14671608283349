import { observable, action, makeObservable } from 'mobx';

class PresenceStore {
  users = {};

  constructor() {
    makeObservable(this, {
      users: observable,
      set: action,
      handleDiff: action,
    });
  }

  set(id, path) {
    this.users[id] = path;
  }

  handleDiff({ leaves, joins }) {
    const users = { ...this.users };
    const joinIds = Object.keys(joins);
    joinIds.forEach(join => {
      users[join] = joins[join].metas[0].path;
      delete leaves[join];
    });
    Object.keys(leaves).forEach(leave => {
      delete users[leave];
    });
    console.log('users in presence store', users);
    this.users = users;
  }
}

const PresenceStoreInstance = new PresenceStore();

export default PresenceStoreInstance;
