export let apiUrl;
export let intercomId;
export let apiBase;
export let apiPrefix;

if (import.meta.env.VITE_APP_API_ENV === 'production') {
  apiUrl = 'https://api.unyted.co';
  intercomId = 'v5bdmu7v';
} else if (import.meta.env.VITE_APP_API_ENV === 'staging') {
  apiUrl = 'https://api-staging.unyted.co';
  intercomId = 'frna39ld';
} else {
  apiUrl = 'https://api-staging.unyted.co';
  intercomId = 'frna39ld';
}

apiBase = apiUrl.split('//')[1];
apiPrefix = apiUrl.split('//')[0];
