import AcademicSubscriptionStore from './models/academic-subscription-store';
import ActivityCategoryStore from './models/activity-category-store';
import ActivityExecutionStore from './models/activity-execution-store';
import ActivityInstanceStore from './models/activity-instance-store';
import ActivityStateStore from './models/activity-state-store';
import ActivityStore from './models/activity-store';
import AreaOfFocusStore from './models/area-of-focus-store';
import AssessmentStore from './models/assessment-store';
import ChildImageOptionStore from './models/child-image-option-store';
import CoachingStore from './models/coaching-store';
import CommentStore from './models/comment-store';
import CompetitionStore from './models/competition-store';
import CompetitionTeamStore from './models/competition-team-store';
import DomainStore from './models/domain-store';
import GameLogEntryStore from './models/game-log-entry-store';
import GradeStore from './models/grade-store';
import JournalEntryStore from './models/journal-entry-store';
import LearningCenterStore from './models/learning-center-store';
import LicenseKeyStore from './models/license-key-store';
import MembershipStore from './models/membership-store';
import MessageStore from './models/message-store';
import OnboardingStore from './models/onboarding-store';
import OrganizationStore from './models/organization-store';
import ParentStore from './models/parent-store';
import ParentCompletionStore from './models/parent-completion-store';
import PlanPurchaseStore from './models/plan-purchase-store';
import PlanStore from './models/plan-store';
import PotentialChildStore from './models/potential-child-store';
import PotentialChildLearningCenterStore from './models/potential-child-learning-center-store';
import PowerSkillStore from './models/power-skill-store';
import ResolutionStore from './models/resolution-store';
import ScheduleStore from './models/schedule-store';
import SkillStore from './models/skill-store';
import StandardCompletionStore from './models/standard-completion-store';
import StandardStore from './models/standard-store';
import SubjectStore from './models/subject-store';
import TalkbackStore from './models/talkback-store';
import TeamActivitiesCompletedStore from './models/team-activities-completed-store';
import TeamGroupStore from './models/team-group-store';
import TeamPositionStore from './models/team-position-store';
import TeamStore from './models/team-store';
import TrackActivityInstanceStore from './models/track-activity-instance-store';
import TrackActivityStore from './models/track-activity-store';
import TrackInstanceStore from './models/track-instance-store';
import TrackStore from './models/track-store';
import UlcEventStore from './models/ulc-event-store';
import UlcRawAvailabilityStore from './models/ulc-raw-availability-store';
import UserStore from './models/user-store';
import UserMessageStore from './models/user-messages-store';

export class RootModelStore {
  constructor() {
    this.AcademicSubscriptionStore = new AcademicSubscriptionStore(this);
    this.ActivityCategoryStore = new ActivityCategoryStore(this);
    this.ActivityExecutionStore = new ActivityExecutionStore(this);
    this.ActivityInstanceStore = new ActivityInstanceStore(this);
    this.ActivityStateStore = new ActivityStateStore(this);
    this.ActivityStore = new ActivityStore(this);
    this.AreaOfFocusStore = new AreaOfFocusStore(this);
    this.AssessmentStore = new AssessmentStore(this);
    this.ChildImageOptionStore = new ChildImageOptionStore(this);
    this.CoachingStore = new CoachingStore(this);
    this.CommentStore = new CommentStore(this);
    this.CompetitionStore = new CompetitionStore(this);
    this.CompetitionTeamStore = new CompetitionTeamStore(this);
    this.DomainStore = new DomainStore(this);
    this.EventStore = new UlcEventStore(this);
    this.GameLogEntryStore = new GameLogEntryStore(this);
    this.GradeStore = new GradeStore(this);
    this.JournalEntryStore = new JournalEntryStore(this);
    this.LearningCenterStore = new LearningCenterStore(this);
    this.LicenseKeyStore = new LicenseKeyStore(this);
    this.MembershipStore = new MembershipStore(this);
    this.MessageStore = new MessageStore(this);
    this.OnboardingStore = new OnboardingStore(this);
    this.OrganizationStore = new OrganizationStore(this);
    this.ParentStore = new ParentStore(this);
    this.ParentCompletionStore = new ParentCompletionStore(this);
    this.PlanPurchaseStore = new PlanPurchaseStore(this);
    this.PlanStore = new PlanStore(this);
    this.PotentialChildStore = new PotentialChildStore(this);
    this.PotentialChildLearningCenterStore =
      new PotentialChildLearningCenterStore(this);
    this.PowerSkillStore = new PowerSkillStore(this);
    this.RawAvailabilityStore = new UlcRawAvailabilityStore(this);
    this.ResolutionStore = new ResolutionStore(this);
    this.ScheduleStore = new ScheduleStore(this);
    this.SkillStore = new SkillStore(this);
    this.StandardCompletionStore = new StandardCompletionStore(this);
    this.StandardStore = new StandardStore(this);
    this.SubjectStore = new SubjectStore(this);
    this.TalkbackStore = new TalkbackStore(this);
    this.TeamActivitiesCompletedStore = new TeamActivitiesCompletedStore();
    this.TeamGroupStore = new TeamGroupStore(this);
    this.TeamPositionStore = new TeamPositionStore(this);
    this.TeamStore = new TeamStore(this);
    this.TrackActivityInstanceStore = new TrackActivityInstanceStore(this);
    this.TrackActivityStore = new TrackActivityStore(this);
    this.TrackInstanceStore = new TrackInstanceStore(this);
    this.TrackStore = new TrackStore(this);
    this.UserStore = new UserStore(this);
    this.UserMessageStore = new UserMessageStore(this);
    this.handleIncomingMessage = this.handleIncomingMessage.bind(this);
  }

  setSessionStore(sessionStore) {
    this.sessionStore = sessionStore;
  }

  get stores() {
    return {
      AcademicSubscriptionStore: this.AcademicSubscriptionStore,
      ActivityCategoryStore: this.ActivityCategoryStore,
      ActivityExecutionStore: this.ActivityExecutionStore,
      ActivityInstanceStore: this.ActivityInstanceStore,
      ActivityStateStore: this.ActivityStateStore,
      ActivityStore: this.ActivityStore,
      AreaOfFocusStore: this.AreaOfFocusStore,
      AssessmentStore: this.AssessmentStore,
      ChildImageOptionStore: this.ChildImageOptionStore,
      CoachingStore: this.CoachingStore,
      CommentStore: this.CommentStore,
      CompetitionStore: this.CompetitionStore,
      CompetitionTeamStore: this.CompetitionTeamStore,
      DomainStore: this.DomainStore,
      EventStore: this.EventStore,
      GameLogEntryStore: this.GameLogEntryStore,
      GradeStore: this.GradeStore,
      JournalEntryStore: this.JournalEntryStore,
      LearningCenterStore: this.LearningCenterStore,
      LicenseKeyStore: this.LicenseKeyStore,
      MembershipStore: this.MembershipStore,
      MessageStore: this.MessageStore,
      OnboardingStore: this.OnboardingStore,
      OrganizationStore: this.OrganizationStore,
      ParentStore: this.ParentStore,
      ParentCompletionStore: this.ParentCompletionStore,
      PlanPurchaseStore: this.PlanPurchaseStore,
      PlanStore: this.PlanStore,
      PotentialChildStore: this.PotentialChildStore,
      PotentialChildLearningCenterStore: this.PotentialChildLearningCenterStore,
      PowerSkillStore: this.PowerSkillStore,
      RawAvailabilityStore: this.RawAvailabilityStore,
      ResolutionStore: this.ResolutionStore,
      ScheduleStore: this.ScheduleStore,
      SkillStore: this.SkillStore,
      StandardCompletionStore: this.StandardCompletionStore,
      StandardStore: this.StandardStore,
      SubjectStore: this.SubjectStore,
      TalkbackStore: this.TalkbackStore,
      TeamActivitiesCompletedStore: this.TeamActivitiesCompletedStore,
      TeamGroupStore: this.TeamGroupStore,
      TeamPositionStore: this.TeamPositionStore,
      TeamStore: this.TeamStore,
      TrackActivityInstanceStore: this.TrackActivityInstanceStore,
      TrackActivityStore: this.TrackActivityStore,
      TrackInstanceStore: this.TrackInstanceStore,
      TrackStore: this.TrackStore,
      UserStore: this.UserStore,
      UserMessageStore: this.UserMessageStore,
    };
  }

  get modelsHandled() {
    return [
      'AcademicSubscription',
      'Activity',
      'ActivityCategory',
      'ActivityExecution',
      'ActivityInstance',
      'ActivityState',
      'AreaOfFocus',
      'Availability',
      'ChildImageOption',
      'Coaching',
      'Comment',
      'Competition',
      'CompetitionTeam',
      'Domain',
      'Event',
      'GameLogEntry',
      'Grade',
      'JournalEntry',
      'LearningCenter',
      'LicenseKey',
      'Membership',
      'Message',
      'Onboarding',
      'Organization',
      'Parent',
      'ParentCompletion',
      'Plan',
      'PlanPurchase',
      'PotentialChild',
      'PotentialChildLearningCenter',
      'Resolution',
      'Schedule',
      'Skill',
      'Standard',
      'Subject',
      'Talkback',
      'Team',
      'TeamGroup',
      'TeamPosition',
      'TrackActivity',
      'TrackActivityInstance',
      'TrackInstance',
      'TrackStore',
      'User',
    ];
  }

  /**
   * Handle incoming websocket messages to the MobX store system.
   *
   * This handles the message and properly instructs the correct store.
   *
   * @param {object} message - The incoming message.
   * @param {string} message.model - The name of the model the message
   *   concerns.
   * @param {string} message.type - The type of message, namely INSERT, UPDATE,
   *   or DELETE
   * @param {object} message.value - The data of the model to update. Must
   *   contain an `id` value. Other values are determined by `model`.
   */
  handleIncomingMessage(message) {
    console.log('handling message!', message);

    const store = this.stores[this.storeForModel(message.model)];
    if (message.type === 'INSERT' || message.type === 'UPDATE') {
      store.add(message.value);
    }
    if (message.type === 'DELETE') {
      store.remove(message.value.id);
    }
  }

  storeForModel(model) {
    if (model === 'Availability') return 'RawAvailabilityStore';
    return `${model}Store`;
  }
}

const RootModelStoreInstance = new RootModelStore();

export default RootModelStoreInstance;
