import { observable, action, makeObservable } from 'mobx';

class SpinnerStore {
  active = false;

  constructor() {
    makeObservable(this, {
      active: observable,
      on: action.bound,
      off: action.bound,
    });
  }

  on() {
    this.active = true;
  }

  off() {
    this.active = false;
  }
}

const SpinnerStoreInstance = new SpinnerStore();

export default SpinnerStoreInstance;
