import _ from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';
import moment from 'moment';
import apiActions from '../../api/actions';

export class ActivityState {
  constructor(data, root) {
    this._root = root;
    this.activity_instance_id = data.activity_instance_id;
    this.created_at = data.created_at;
    this.evaluation = data.evaluation;
    this.group_staging = data.group_staging;
    this.id = data.id;
    this.individual_scores = data.individual_scores;
    this.individual_staging = data.individual_staging;
    this.joined = data.joined;
    this.performance = data.performance;
    this.timestamp = data.timestamp;
    this.total_team_points = data.total_team_points;
    this.updated_at = data.updated_at;
  }

  get instance() {
    return this._root.ActivityInstanceStore.find(this.activity_instance_id);
  }

  individualStagingStartedAt(user) {
    return (
      this.individual_staging[user.machine_name] &&
      this.individual_staging[user.machine_name].started
    );
  }

  individualStagingTimeUsed(user) {
    if (!this.individualStagingStartedAt(user)) return false;
    const start = moment.utc(this.individualStagingStartedAt);
    const now = moment.utc();
    return now.diff(start);
  }

  individiualStagingTimeRemaining(user) {
    if (!this.individualStagingStartedAt(user)) return false;
    const timeUsed = this.individualStagingTimeUsed(user);
    const timeLimit = moment.duration(
      this.instance.activity.staging.individual_time,
      'seconds',
    );
    return timeLimit.subtract(timeUsed);
  }

  get individualStagingComplete() {
    return this.instance.participatingUsers.every(user =>
      user
        ? this.individual_staging[user.machine_name] &&
          this.individual_staging[user.machine_name].completed
        : true,
    );
  }

  get groupStagingStartedAt() {
    return this.group_staging.started;
  }

  get groupStagingTimeUsed() {
    if (!this.groupStagingStartedAt) return false;
    const start = moment.utc(this.groupStagingStartedAt);
    const now = moment.utc();
    return now.diff(start);
  }

  get groupStagingTimeRemaining() {
    if (!this.groupStagingStartedAt) return false;
    const timeUsed = this.groupStagingTimeUsed;
    const timeLimit = moment.duration(
      this.instance.activity.staging.group_time,
      'seconds',
    );
    return timeLimit.subtract(timeUsed);
  }

  get groupStagingComplete() {
    return this.group_staging.completed;
  }

  get performanceStartedAt() {
    return this.performance.started;
  }

  get performanceTimeUsed() {
    if (!this.performanceStartedAt) return false;
    const start = moment.utc(this.performanceStartedAt);
    const now = moment.utc();
    return now.diff(start);
  }

  get performanceTimeRemaining() {
    if (!this.performanceStartedAt) return false;
    const timeUsed = this.performanceTimeUsed;
    const timeLimit = moment.duration(this.instance.activity.time, 'seconds');
    return timeLimit.subtract(timeUsed);
  }

  get performanceComplete() {
    return this.performance.completed;
  }

  get evaluationComplete() {
    return this.instance.participatingUsers.every(user =>
      user
        ? this.evaluation[user.machine_name] &&
          this.evaluation[user.machine_name].completed
        : true,
    );
  }

  get phasesComplete() {
    const phases = [
      this.individualStagingComplete,
      this.groupStagingComplete,
      this.performanceComplete,
      this.evaluationComplete,
    ];
    return phases.filter(phase => phase).length;
  }
}

export default class ActivityStateStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      notifyOnUpdate: observable,
      externalRefreshRequired: observable,
      add: action,
      toggleUpdateNotifications: action,
      externalRefreshCompleted: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchStatesForParent: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      fetchByActivityInstance: action,
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();
  notifyOnUpdate = false;
  externalRefreshRequired = false;

  add = data => {
    if (this.notifyOnUpdate && !this.externalRefreshRequired) {
      this.externalRefreshRequired = true;
    }

    const activityState = new ActivityState(data, this.root);
    this.data.set(activityState.id, activityState);
  };

  toggleUpdateNotifications() {
    this.notifyOnUpdate = !this.notifyOnUpdate;
  }

  externalRefreshCompleted() {
    this.externalRefreshRequired = false;
  }

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const activityState = new ActivityState(data, this.root);
      merge.set(activityState.id, activityState);
    });
    this.data.merge(merge);
  };

  remove = id => {
    if (id && this.data.has(id)) {
      this.data.delete(id);
    }
  };

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  };

  fetchStatesForParent(parentId) {
    this.requests.set('parent', 'pending');
    return apiActions
      .index('activity_states', { parent_id: parentId })
      .then(response => {
        this.addMany(response.data);
        this.requests.set('parent', 'complete');
      });
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('activity_states').then(response => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(
      keys.map(key => this.fetchByActivityInstance(key.split(':')[1])),
    );
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByActivityInstance(activityInstanceId) {
    const requestKey = `activityInstance:${activityInstanceId}`;
    if (this.requests.get(requestKey) === undefined) {
      this.requests.set(requestKey, 'pending');
      return apiActions
        .index('activity_states', { activity_instance_id: activityInstanceId })
        .then(response => {
          this.addMany(response.data);
          this.requests.set(requestKey, 'complete');
        });
    }
    return Promise.resolve();
  }

  fetchByActivityInstanceCompleted(activityInstanceId) {
    const requestKey = `activityInstance:${activityInstanceId}`;
    return this.requests.get(requestKey) === 'complete';
  }

  forActivityInstance(activityInstanceId) {
    return _.find(
      Array.from(this.data.values()),
      activityState =>
        activityState.activity_instance_id === parseInt(activityInstanceId, 10),
    );
  }
}
