import { observable, action, computed, makeObservable } from 'mobx';
import apiActions from '../../api/actions';
import imagePath from '../../utils/imagePath';

export default class OrganizationStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      length: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const organization = new Organization(data, this.root);
    this.data.set(organization.id, organization);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const organization = new Organization(data, this.root);
      merge.set(organization.id, organization);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('organizations').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  get length() {
    return this.data.size;
  }
}

export class Organization {
  constructor(data, root) {
    this._root = root;
    this.active = data.active;
    this.admin_id = data.admin_id;
    this.auth_mode = data.auth_mode;
    this.created_at = data.created_at;
    this.id = data.id;
    this.image = data.image;
    this.image_content_type = data.image_content_type;
    this.image_file_name = data.image_file_name;
    this.image_file_size = data.image_file_size;
    this.image_updated_at = data.image_updated_at;
    this.mission = data.mission;
    this.name = data.name;
    this.trial = data.trial || false;
    this.is_school = data.is_school;
    this.updated_at = data.updated_at;
    this.vision = data.vision;
  }

  get licenseKeys() {
    return this._root.LicenseKeyStore.forOrganization(this.id);
  }

  get teams() {
    return this._root.TeamStore.forOrganization(this.id);
  }

  get imagePath() {
    return imagePath(this, 'organizations');
  }

  get activityInstances() {
    return this._root.ActivityInstanceStore.forOrganization(this.id);
  }

  get completedActivityInstances() {
    return this._root.ActivityInstanceStore.completedForOrganization(this.id);
  }

  get memberships() {
    return this._root.MembershipStore.forOrganization(this.id);
  }

  get users() {
    return this.memberships.map(membership => membership.user);
  }
}
