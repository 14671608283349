import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';

export class UserMessage {
  constructor(data, root) {
    this._root = root;
    this.body = data.body;
    this.subject = data.subject;
    this.id = data.id;
    this.from_user = data.from_user;
    this.to_user = data.to_user;
    this.subject_user = data.subject_user;
    this.inserted_at = data.inserted_at;
  }
}

export default class UserMessageStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      add: action,
      addMany: action,
      all: computed,
      reset: action,
    });

    this.root = root;
  }

  data = new Map();
  
  add = (data) => {
    const userMessage = new UserMessage(data, this.root);
    this.data.set(userMessage.id, userMessage);
  }
  update = (data) => {
    const userMessage = new UserMessage(data, this.root);
    this.data.set(userMessage.id, userMessage);
  }
  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const userMessage = new UserMessage(data, this.root);
      merge.set(userMessage.id, userMessage);
    });
    this.data.merge(merge);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
  }

  threadForUser(userId) {
    const selfId = this.root.sessionStore.currentUser.id;
    return _.chain(this.all)
      .filter((message) => message.from_user === selfId ? message.to_user === userId : message.from_user === userId)
      .sortBy('inserted_at')
      .value();
  }
}
