import { observable, action, computed, makeObservable } from 'mobx';
import apiActions from '../../api/actions';

export class Schedule {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.start_date = data.start_date;
    this.end_date = data.end_date;
    this.start_time = data.start_time;
    this.end_time = data.end_time;
    this.days = data.days;
    this.learning_center_id = data.learning_center_id;
    this.plan_id = data.plan_id;
  }

  get plan() {
    return this._root.PlanStore.find(this.plan_id);
  }

  get purchasesForScheduleByPlanId() {
    if (this.plan) {
      return this._root.PlanPurchaseStore.purchasesForPlan(this.plan.id);
    }

    return [];
  }

  parentForSchedulePurchase(purchaseId) {
    return this._root.ParentStore.parentForPurchase(purchaseId);
  }
}

export default class ScheduleStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      fetchByLearningCenter: action
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const schedule = new Schedule(data, this.root);
    this.data.set(schedule.id, schedule);
  }

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const schedule = new Schedule(data, this.root);
      merge.set(schedule.id, schedule);
    });
    this.data.merge(merge);
  }

  remove = id => {
    this.data.delete(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('schedules').then(response => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByLearningCenter(id) {
    const key = `learningCenter:${id}`;
    if (this.requests.get(key) === undefined) {
      this.requests.set(key, 'pending');
      return apiActions.rawGet(`learning_centers/${id}/schedules`).then(response => {
        this.addMany(response.data);
        this.requests.set(key, 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchByLearningCenterCompleted(id) {
    const key = `learningCenter:${id}`;
    return this.requsets.get(key) === 'complete';
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }
}

