import { observable, action, makeObservable, toJS } from 'mobx';
import _ from 'lodash';

class GrowlStore {
  alerts = [];

  constructor() {
    makeObservable(this, {
      alerts: observable,
      generate: action,
      pop: action,
    });
  }

  generate({ message, type = 'success', headline = '', id }) {
    if (!_.some(this.alerts, ['id', id])) {
      const newAlert = {
        id,
        type,
        headline,
        message,
      };
      this.alerts = [...this.alerts, newAlert];
    }
  }

  pop(alert) {
    // find the index of the alert that was dismissed
    const alerts = toJS(this.alerts);
    const idx = _.findIndex(alerts, alert);

    if (idx >= 0) {
      // remove the alert from the array
      this.alerts = [...alerts.slice(0, idx), ...alerts.slice(idx + 1)];
    }
  }
}

const GrowlStoreInstance = new GrowlStore();

export default GrowlStoreInstance;
