import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from '../../api/actions';
import imagePath from '../../utils/imagePath';

export default class ActivityStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      fetchById: action,
      refetch: action,
      fetchAllCompleted: computed,
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const activity = new Activity(data, this.root);
    this.data.set(activity.id, activity);
  };

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const activity = new Activity(data, this.root);
      merge.set(activity.id, activity);
    });
    this.data.merge(merge);
  };

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  };

  find(id) {
    return this.data.get(id);
  }

  differentiationActivities(category) {
    return _.filter(
      this.all,
      activity => activity.sel_differentiation_category === category,
    );
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  };

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('activities').then(response => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchById(id) {
    if (this.requests.get(id) === undefined) {
      this.requests.set(id, 'pending');
      return apiActions.get('activities', id).then(response => {
        this.add(response.data);
        this.requests.set(id, 'complete');
        return this.find(id);
      });
    }
    return Promise.resolve(this.find(id));
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(
      keys.map(key => {
        return this.fetchById(key);
      }),
    );
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByIdCompleted(id) {
    return this.requests.get(id) === 'complete';
  }

  /**
   * Get the current versions of all activities.
   */
  currentActivities(currentUserId) {
    const activities = Array.from(this.data.values());
    return _.reject(
      activities,
      activity =>
        activity.legacy ||
        _.find(activities, ['previous_version_id', activity.id]) ||
        (activity.private && activity.created_by_id !== Number(currentUserId)),
    );
  }

  previousVersions(currentUserId) {
    const current = this.currentActivities(currentUserId);
    const previous = this.all.filter(
      activity =>
        !activity.legacy &&
        (!activity.private ||
          (activity.private &&
            activity.created_by_id === Number(currentUserId))),
    );
    return _.differenceBy(previous, current, 'id');
  }

  activitiesForSkill(currentUserId, skillId) {
    return this.currentActivities(currentUserId).filter(
      ({ activity_standards }) => {
        return _.some(activity_standards, activity_standard =>
          _.includes(_.get(activity_standard, 'selected_skills', []), skillId),
        );
      },
    );
  }
}

export class Activity {
  constructor(data, root) {
    this._root = root;
    this.activity_category_id = data.activity_category_id;
    this.activity_standards = data.activity_standards;
    this.sel_differentiation_category = data.sel_differentiation_category;
    this.attachments = data.attachments;
    this.created_at = data.created_at;
    this.created_by_id = data.created_by_id;
    this.description = data.description;
    this.environment_description = data.environment_description;
    this.goals_description = data.goals_description;
    this.hide_answers = data.hide_answers;
    this.id = data.id;
    this.image_content_type = data.image_content_type;
    this.image_file_name = data.image_file_name;
    this.image_file_size = data.image_file_size;
    this.image_updated_at = data.image_updated_at;
    this.instructions = data.instructions;
    this.objectives = data.objectives;
    this.organization_id = data.organization_id;
    this.points = data.points;
    this.preferences = data.preferences;
    this.previous_version_id = data.previous_version_id;
    this.staging = data.staging;
    this.steps = data.steps;
    this.time = data.time;
    this.title = data.title;
    this.total_points = data.total_points;
    this.updated_at = data.updated_at;
    this.assessment_id = data.assessment_id;
    this.standards = data.standards;
    this.private = data.private;
    this.legacy = data.legacy;
  }

  get version() {
    return this._calculateVersion();
  }

  _calculateVersion(count = 1) {
    if (!this.previous_version_id) {
      return count;
    }
    const nextActivity = this._root.ActivityStore.find(
      this.previous_version_id,
    );
    if (nextActivity) return nextActivity._calculateVersion(count + 1);
    return count;
  }

  /* This could be entirely removed or just become a getter for
   * `this.preferences;, but there are parts of the app that actually depend
   * on these keys being in a specific order. We should refactor those soon
   * as this is a big no-no in Javascript.
   */
  get calculatedPreferences() {
    return {
      individual_staging:
        Object.keys(this.preferences).length === 0 ||
        this.preferences.individual_staging,
      group_staging:
        Object.keys(this.preferences).length === 0 ||
        this.preferences.group_staging,
      performance:
        Object.keys(this.preferences).length === 0 ||
        this.preferences.performance,
      evaluation:
        Object.keys(this.preferences).length === 0 ||
        this.preferences.evaluation,
    };
  }

  get imagePath() {
    return imagePath(this, 'activities');
  }

  get creator() {
    return this._root.UserStore.find(this.created_by_id);
  }

  get totalIndividualStagingPoints() {
    return this.staging.steps.reduce(
      (sum, { points }) => sum + parseInt(points, 10),
      0,
    );
  }

  get totalGroupStagingPoints() {
    return this.staging.steps.reduce(
      (sum, { points }) => sum + parseInt(points, 10),
      0,
    );
  }

  get totalPerformancePoints() {
    return this.steps.reduce(
      (sum, { points }) => sum + parseInt(points, 10),
      0,
    );
  }

  get totalPoints() {
    const individual = this.calculatedPreferences.individual_staging
      ? this.totalIndividualStagingPoints
      : 0;
    const group = this.calculatedPreferences.group_staging
      ? this.totalGroupStagingPoints
      : 0;
    const performance = this.calculatedPreferences.performance
      ? this.totalPerformancePoints
      : 0;
    return individual + group + performance;
  }

  get totalTimeMinutes() {
    const preferences = this.calculatedPreferences;
    const { staging, time } = this;
    let activityTime = 0;

    if (
      preferences &&
      (Object.keys(preferences).length === 0 || preferences.performance)
    ) {
      activityTime = activityTime + time;
    }
    if (staging && Object.keys(staging).length > 0) {
      if (Object.keys(preferences).length === 0 || preferences.group_staging) {
        activityTime = activityTime + staging.group_time;
      }
      if (
        Object.keys(preferences).length === 0 ||
        preferences.individual_staging
      ) {
        activityTime = activityTime + staging.individual_time;
      }
    }

    return Number((activityTime / 60).toFixed());
  }

  get firstPhase() {
    const preferences = this.calculatedPreferences;
    if (preferences.individual_staging) return 'individual_staging';
    if (preferences.group_staging) return 'group_staging';
    if (preferences.performance) return 'performance';
    return false;
  }

  get lastPhase() {
    const preferences = this.calculatedPreferences;
    if (preferences.evaluation) return 'evaluation';
    if (preferences.performance) return 'performance';
    if (preferences.group_staging) return 'group_staging';
    if (preferences.individual_staging) return 'individual_staging';
    return false;
  }

  get isCollaborative() {
    const preferences = this.calculatedPreferences;
    return preferences.performance || preferences.group_staging;
  }

  get totalTime() {
    const preferences = this.calculatedPreferences;
    const individual = preferences.individual_staging
      ? this.staging.individual_time
      : 0;
    const groupStaging = preferences.group_staging
      ? this.staging.group_time
      : 0;
    const performance = preferences.performance ? this.time : 0;
    return individual + groupStaging + performance;
  }

  get totalPhases() {
    return _.filter(this.calculatedPreferences).length;
  }

  get numberOfSteps() {
    const preferences = this.calculatedPreferences;
    const individual = preferences.individual_staging
      ? this.staging.steps.length
      : 0;
    const groupStaging = preferences.group_staging
      ? this.staging.steps.length
      : 0;
    const performance = preferences.performance ? this.steps.length : 0;
    return individual + groupStaging + performance;
  }

  levelForPhase(phaseName) {
    const phases = this.calculatedPreferences;
    const order = [
      'individual_staging',
      'group_staging',
      'performance',
      'evaluation',
    ];
    const levels = order.filter(phase => phases[phase]);
    return _.findIndex(levels, level => level === phaseName) + 1;
  }

  get academicStandards() {
    return this.activity_standards.map(({ standard_id }) =>
      this._root.StandardStore.find(standard_id),
    );
  }

  get academicSkills() {
    return _.flatMap(this.activity_standards, 'selected_skills').map(skillId =>
      this._root.SkillStore.find(skillId),
    );
  }
}
