import { observable, action, makeObservable } from 'mobx';
import _ from 'lodash';

/**
 * Manage state and transitions in playthrough phases, tied to the server-emitted FLASH message.
 *
 * Components should probably register callbacks to accomplish work in reaction to the user interacting with the rendered button.
 * See the `<Flash>` component for more details.
 *
 * There are two callback types available, differentiated by when they are run in relation to the incoming FLASH alert:
 *
 * * forward callbacks
 * * callbacks
 *
 * When this data store receives a new alert, it:
 *
 * 1. Checks for alert uniqueness
 * 2. Runs all callbacks in `forwardCallbacks` about 200ms before adding the alert to the store
 * 3. Listens for `dismiss` for a particular alert (tied to user clicking the button)
 * 4. Runs all callbacks in `callbacks` when removing dismissed alert
 *
 * With this setup, we can manage our playthrough learning scenes:
 *
 * * Using `registerForwardCallback`, we can hook in a "tear down", "fade out", or "point camera at door" function
 * * Using `registerCallback`, we can hook in a "set up", "fade in", or "move camera through door to position X,Y,Z" function
 * * Once the phase is over, our playthrough component can reset the FlashStore by unregistering callbacks (`deregisterCallback`, `deregisterForwardCallback`) or resetting them (`emptyCallbacks()`, `emptyForwardCallbacks()`)
 *
 * In terms of logic loop, it looks like this:
 *
 * 0. Playthrough component registers callbacks (and forward callbacks)
 * 1. Student selects answer and hits 'Submit'
 * 2. Server evaluates student's answer and sends their client a FLASH message
 * 3. Web app receives flash message and FlashStore runs every function in `forwardCallbacks`
 * 4. Approx 200 ms later, FLASH message alert is added to store and renders on-screen flying button (via `<Flash>` component)
 * 5. Student clicks flying button and FlashStore runs every function in `callbacks`
 * 6. Steps 1 - 5 continue until the phase is complete. In the last Step 5, the playthrough component is responsible for determining when to push the user out of this scene.
 **/
class FlashStore {
  constructor() {
    makeObservable(this, {
      alerts: observable,
      received: observable,
      registerCallback: action,
      registerForwardCallback: action,
      deregisterCallback: action,
      deregisterForwardCallback: action,
      emptyCallbacks: action,
      emptyForwardCallbacks: action,
      generate: action,
      dismiss: action,
    });

    this.callbacks = [];
    this.forwardCallbacks = [];
  }
  alerts = [];

  received = true;

  registerCallback(cb) {
    this.callbacks.push(cb);
  }

  registerForwardCallback(cb) {
    this.forwardCallbacks.push(cb);
  }

  deregisterCallback(cb) {
    this.callbacks = this.callbacks.filter(c => c !== cb);
  }

  deregisterForwardCallback(cb) {
    this.forwardCallbacks = this.forwardCallbacks.filter(c => c !== cb);
  }

  emptyCallbacks() {
    this.callbacks = [];
  }

  emptyForwardCallbacks() {
    this.forwardCallbacks = [];
  }

  generate(message, type, id) {
    if (!_.some(this.alerts, ['id', id])) {
      const newAlert = { message, type, id };
      this.forwardCallbacks.forEach(cb => cb(newAlert));
      setTimeout(() => {
        this.alerts = [...this.alerts, newAlert];
        this.received = false;
        const func = () => (this.received = true);
        setTimeout(func.bind(this), 200);
      }, 200);
    }
  }

  dismiss() {
    this.alerts.shift();
    this.callbacks.forEach(cb => cb());
  }
}

const FlashStoreInstance = new FlashStore();

export default FlashStoreInstance;
