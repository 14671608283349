import { observable, action, computed, makeObservable } from 'mobx';
import apiActions from '../../api/actions';

export default class LicenseKeyStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      length: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const licenseKey = new LicenseKey(data, this.root);
    this.data.set(licenseKey.id, licenseKey);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const licenseKey = new LicenseKey(data, this.root);
      merge.set(licenseKey.id, licenseKey);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('license_keys').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  get length() {
    return this.data.size;
  }

  get all() {
    return Array.from(this.data.values());
  }

  forOrganization(orgId) {
    return Array.from(this.data.values()).filter(key => key.organization_id === orgId);
  }
}

export class LicenseKey {
  constructor(data, root) {
    this._root = root;
    this.combination = data.combination;
    this.created_at = data.created_at;
    this.id = data.id;
    this.invited = data.invited;
    this.organization_id = data.organization_id;
    this.updated_at = data.updated_at;
    this.user_id = data.user_id;
  }

  get user() {
    return this._root.UserStore.find(this.user_id);
  }
}
