import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';

export class ParentCompletion {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.parent_id = data.parent_id;
    this.user_id = data.user_id;
    this.power_skill_level_id = data.power_skill_level;
    this.skill_id = data.skill;
    this.inserted_at = data.inserted_at;
  }

  get powerSkillLevel() {
    return this._root.PowerSkillStore.findLevel(this.power_skill_level_id);
  }

  get skill() {
    return this._root.SkillStore.find(this.skill_id);
  }
}

export default class ParentCompletionStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      add: action,
      addMany: action,
      all: computed,
      reset: action,
    });

    this.root = root;
  }

  data = new Map();

  add = (data) => {
    const parentCompletion = new ParentCompletion(data, this.root);
    this.data.set(parentCompletion.id, parentCompletion);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const parentCompletion = new ParentCompletion(data, this.root);
      merge.set(parentCompletion.id, parentCompletion);
    });
    this.data.merge(merge);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
  }

  forPowerSkillLevel(parentId, levelId) {
    return _.filter(this.all, { parent_id: parentId, power_skill_level_id: levelId });
  }

  forAcademicSkill(parentId, skillId) {
    return _.filter(this.all, { parent_id: parentId, skill_id: skillId });
  }
}
