import { observable, action, makeObservable } from 'mobx';

class CategoriesStore {
  currentCategoryId = 0;

  updateCategory = id => {
    this.currentCategoryId = id;
  };

  constructor() {
    makeObservable(this, {
      currentCategoryId: observable,
      updateCategory: action,
    });
  }
}

const CategoriesStoreInstance = new CategoriesStore();

export default CategoriesStoreInstance;
