import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export class StandardCompletion {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.activity_title = data.activity_title;
    this.completed_at = data.completed_at;
    this.name = data.name;
    this.standard_ids = data.standard_ids;
    this.skill_ids = data.skill_ids;
  }

  get standards() {
    return _.map(this.standard_ids, standardId => this._root.StandardStore.find(standardId));
  }

  get domains() {
    return _.map(this.standards, 'domain');
  }

  get skills() {
    return _.map(this.skill_ids, skillId => this._root.SkillStore.find(skillId));
  }
}

export default class StandardCompletionStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchForUser: action,
      refetchForUser: action
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const standard = new StandardCompletion(data, this.root);
    this.data.set(standard.id, standard);
  }

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const standard = new StandardCompletion(data, this.root);
      merge.set(standard.id, standard);
    });
    this.data.merge(merge);
  }

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchForUser(userId) {
    const key = `user:${userId}`;
    if (this.requests.get(key) === undefined) {
      this.requests.set(key, 'pending');
      return apiActions.rawGet(`academics/user/${userId}`).then((response) => {
        this.addMany(response.data);
        this.requests.set(key, 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchForParentUser(userId) {
    const key = `user:${userId}`;
    if (this.requests.get(key) === undefined) {
      this.requests.set(key, 'pending');
      return apiActions.rawGet(`assessments/${userId}/academic`).then((response) => {
        this.addMany(response.data);
        this.requests.set(key, 'complete');
      });
    }
  }

  refetchForUser(userId) {
    const key = `user:${userId}`;
    if (this.requests.get(key) !== undefined) {
      this.reset();
      return this.fetchForUser(userId);
    }
  }

  refetchForParentUser(userId) {
    const key = `user:${userId}`;
    if (this.requests.get(key) !== undefined) {
      this.reset();
      return this.fetchForParentUser(userId);
    }
  }

  fetchForUserCompleted(userId) {
    return this.requests.get(`user:${userId}`) === 'complete';
  }

  fetchForParentUserCompleted(userId) {
    return this.requests.get(`user:${userId}`) === 'complete';
  }

  completionsForStandard(standardId) {
    return this.all.filter(completion => _.includes(completion.standard_ids, standardId));
  }

  completionsForSkill(skillId) {
    return this.all.filter(completion => _.includes(completion.skill_ids, skillId));
  }
}
