import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export default class TeamGroupStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      length: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const teamGroup = new TeamGroup(data, this.root);
    this.data.set(teamGroup.id, teamGroup);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const teamGroup = new TeamGroup(data, this.root);
      merge.set(teamGroup.id, teamGroup);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('team_groups').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  get length() {
    return this.data.size;
  }

  forTeam(teamId) {
    return this.all.filter(group => group.team_id === teamId);
  }
}

export class TeamGroup {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.team_id = data.team_id;
    this.user_ids = data.user_ids;
    this.created_at = data.created_at;
  }

  get team() {
    return this._root.TeamStore.find(this.team_id);
  }

  get users() {
    return _(this.user_ids)
      .map((userId) => this._root.UserStore.find(userId))
      .filter('active')
      .sortBy(['last_name'])
      .value();
  }

  get number() {
    const groups = this._root.TeamGroupStore.forTeam(this.team_id);
    const sorted = _.sortBy(groups, ['created_at']);
    const index = _.findIndex(sorted, ['id', this.id]);
    return index + 1;
  }
}
