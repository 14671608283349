import { observable, action, computed, makeObservable } from 'mobx';
import apiActions from 'api/actions';

export class Standard {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.name = data.name;
    this.domain_id = data.domain_id;
    this.skill_ids = data.skill_ids;
  }

  get skills() {
    return this.skill_ids.map(skillId => this._root.SkillStore.find(skillId));
  }

  get domain() {
    return this._root.DomainStore.find(this.domain_id);
  }

  get gradeLevel() {
    return this.domain.gradeLevel;
  }

  get subject() {
    return this.domain.subject;
  }
}

export default class StandardStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const standard = new Standard(data, this.root);
    this.data.set(standard.id, standard);
  }

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const standard = new Standard(data, this.root);
      merge.set(standard.id, standard);
    });
    this.data.merge(merge);
  }

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.rawGet('academics/standards').then((response) => {
        this.addMany(response.data.standards);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  standardsForDomainId(domainId) {
    return this.all.filter(standard => standard.domain_id === domainId);
  }
}
