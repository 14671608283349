import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export default class PlanStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const plan = new Plan(data, this.root);
    this.data.set(plan.id, plan);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const plan = new Plan(data, this.root);
      merge.set(plan.id, plan);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  findByIdOrSlug(idOrSlug) {
    return this.all.find(service => Number(idOrSlug) === Number(service.id) || idOrSlug === service.slug);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('/plans').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  forLearningCenter(centerId) {
    return this.all.filter(plan => (
      _.includes(plan.learning_center_ids, centerId) || plan.learning_center_ids.length === 0
    ));
  }
}

export class Plan {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.benefits = data.benefits;
    this.credits = data.credits;
    this.description = data.description;
    this.image_file_name = data.image_file_name;
    this.instruction_hours = data.instruction_hours;
    this.instructor_intro = data.instructor_intro;
    this.learning_center_ids = data.learning_center_ids;
    this.options = data.options;
    this.preset_dates = data.preset_dates;
    this.price = data.price;
    this.quantity = data.quantity;
    this.quantity_remaining = data.quantity_remaining;
    this.short_target_audience = data.short_target_audience;
    this.slug = data.slug;
    this.target_audience = data.target_audience;
    this.title = data.title;
  }

  /**
   * Clone this object without its RootStore connection.
   *
   * This allows us to store the object in History API `state` without breaking
   * navigation because its also trying to serialize every other store
   * recursively.
   */
  shallowClone() {
    return _.omit(this, ['_root']);
  }
}
