import React from 'react';
import eventList from './event-list';
import HelperEventStore from 'stores/helper-event-store';
import categories from 'components/shared/report-card/categories';
import styles from './styles.module.scss';

export default class HelperModal {
  static humanFormatFor(eventType) {
    if (eventType === 'score') {
      return 'Score';
    }
    if (!eventList[eventType]) {
      return 'Unknown event';
    }

    const { humanFormat } = eventList[eventType];
    return humanFormat;
  }

  static interventionFormatFor(eventType) {
    if (!eventList[eventType]) {
      return 'Unknown Event';
    }

    const { interventionReportName } = eventList[eventType];
    return interventionReportName;
  }

  static activate(eventType, data, identifier) {
    if (!eventList[eventType]) {
      console.warn(
        `You've activated the event ${eventType} and it does not exist`,
      );
      return;
    }

    const { header, message, runnable, icon, skillsAffected, afterRun } =
      eventList[eventType];

    if (!runnable(eventType, data)) {
      return;
    }

    const content = document.createElement('div');
    content.classList.add(styles.helperContent);
    const skillsList = document.createElement('ul');

    if (skillsAffected && skillsAffected.length > 0) {
      skillsAffected.forEach(skill => {
        const skillLi = document.createElement('li');
        const { icon } = categories[skill];

        skillLi.innerHTML = `<img src="${icon}" />&nbsp;${skill}`;
        skillsList.appendChild(skillLi);
      });

      content.innerHTML = `
        ${message}
        <h4>Skills this Affected</h4>
      `;
      content.appendChild(skillsList);
    } else {
      content.innerHTML = `${message}<br />`;
    }

    const alert = callback => {
      const timeStamp = new Date().getTime();

      return {
        alert: {
          title: header || 'Personal Skills Tip',
          icon: icon || null,
          content,
        },
        afterRun: () => {
          if (afterRun) {
            afterRun.forEach(func => func(eventType, data, identifier));
          }
          callback(timeStamp);
        },
        id: timeStamp,
      };
    };

    HelperEventStore.add(alert);
  }
}
